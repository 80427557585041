export default function() {

    $(document).ready(function(){
        $('a[href^="#"]').on('click',function (e) {
            e.preventDefault();

            var target = this.hash;
            var $target = $(target);
            var offset = $('.action-bar').outerHeight()
                        + $('#wpaminbar').outerHeight()
                        + $('.kindling-development-environment-bar').outerHeight();

            $('html, body').stop().animate({
                'scrollTop': $target.offset().top - offset - 50
            }, 300, 'swing');
        });
    });

}
